import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bgImg from '../assets/imgs/bg/gradient.jpg';

const LicenseAgreement = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Лицензионное соглашение</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <Row className='gx-3 gx-md-4 gx-lg-5' >
                  <span>Настоящее лицензионное соглашение (далее — Соглашение), является публичной офертой ИП Разуваева Елена Сергеевна ИНН: 323203455433 ОГРН: 324320000033480 (далее – SpeedyTrade) на предоставление доступа к:</span>
                  <span>сайту https://speedytrade.ru (далее – Сайт);</span>
                  <span>программам, услугам, продуктам и сервисам, предоставляемым на Сайте;</span>
                  <span>на условиях, указанных ниже.</span>
                  <span>В случае несогласия с условиями Соглашения вы должны воздержаться от использования Сайта.</span>
                  <span>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</span>
                  <span>В целях настоящего Соглашения нижеприведенные термины используются в следующем значении:</span>
                  <span>1.1. Сайт – совокупность программ для электронных вычислительных машин и иной информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством информационно-телекоммуникационной сети "Интернет" по доменному имени speedytrade.ru, заключающая в себе функционал интернет-площадки, на которой одни Пользователи (далее также – Продавцы) размещают в публичный доступ свои предложения о продаже их Товара, а другие Пользователи (далее также – Покупатели) приобретают Товар.</span>
                  <span>1.2. Сервисы – созданные, поддерживаемые и предоставляемые автоматизированные средства Сайта, включающие программные инструменты, функционирующие в автоматическом режиме, предоставляющие авторизованным на сайте Пользователям технические возможности для размещения и поиска Объявлений по продаже Товара, заключения Сделок между Пользователями и их исполнения посредством использования Сайта.</span>
                  <span>1.3. Правила Сайта – правила использования Сайта и его Сервисов, размещенные по адресу: https://speedytrade.ru/rules.</span>
                  <span>1.4. Стороны – лица, заключившие между собой настоящее Соглашение.</span>
                  <span>1.5. Пользователь – физическое лицо, обратившееся к Сайту.</span>
                  <span>1.6. Авторизованный Пользователь – Пользователь, осуществивший регистрацию на Сайте.</span>
                  <span>1.7. Пользователь и Авторизованный Пользователь являются лицензиатами по настоящему Соглашению.</span>
                  <span>1.8. Продавец – Авторизованный Пользователь, размещающий с использованием Сервисов Объявление с предложением заключить Сделку в отношении Товара, действующий в собственных интересах или в интересах иного лица.</span>
                  <span>1.9. Покупатель – Авторизованный Пользователь, осуществляющий с использованием Сервисов взаимодействие с Продавцом в отношении Товара и заключение Сделки с ним.</span>
                  <span>1.10. Товар – любое имущество, имущественные права, товар или услуга, в отношении которых Продавец размещает Объявление с использованием Сервисов.</span>
                  <span>1.11. Объявление (также Лот) – информационное сообщение с предложением о Товаре (включая любую сопутствующую информацию), размещаемое Продавцом с использованием Сервисов, адресованное неопределенному кругу лиц.</span>
                  <span>1.12. Сделка – договор в отношении Товара, заключаемый между Продавцом и Покупателем в соответствии с условиями настоящего Соглашения и условиями, указанными в Объявлении, а также в соответствии с условиями агентского договора, размещенного по адресу https://speedytrade.ru/agency (далее – Агентский договор).</span>
                  <span>1.13. Личный кабинет (далее также – аккаунт) – интерфейс взаимодействия Авторизованного Пользователя с Сайтом, позволяющий ему размещать и/или изменять Объявления, изменять пароль и изображение профиля, просматривать историю своих Сделок на Сайте, использовать чат Сайта и другие автоматизированные средства Сайта, доступные исключительно Авторизированным Пользователям.</span>
                  <span>1.14. Регистрационные данные — идентификационные данные Авторизованного Пользователя, предназначенные для его доступа к Личному кабинету. Регистрационные данные состоят из: логина или соответствующего ему адреса электронной почты (далее – логин) и пароля.</span>
                  <span>1.15. Кошелек – лицевой счет Авторизованного Пользователя в Личном кабинете с информацией о доступном балансе для совершения Сделок на Сайте или вывода денежных средств на банковскую карту Авторизованного Пользователя.</span>
                  <span>1.16. Спор – обращение одного Авторизованного Пользователя в отношении другого Авторизованного Пользователя с использованием Сервисов в связи с любым нарушением или подозрением на нарушение положений Соглашения и/или подозрением на факт мошенничества со стороны Авторизованного Пользователя, в отношении которого такое обращение направлено.</span>
                  <span>1.17. Агент – SpeedyTrade, уполномоченный Авторизованным Пользователем Сервиса за вознаграждение совершать от имени Покупателя и за его счет расчеты по исполнению Сделок, заключаемых через Сайт Покупателем с Продавцами. Агент действует в соответствии с условиями настоящего Соглашения и Агентского договора, Покупатель является для Агента Принципалом.</span>
                  <span>1.18. Акцепт – полное и безоговорочное принятие Пользователем условий доступа к Сайту и его Сервисам путем осуществления действий, указанных в Соглашении.</span>
                  <span>2. ПРЕДМЕТ СОГЛАШЕНИЯ</span>
                  <span>2.1. SpeedyTrade предоставляет Пользователю право использования Сайта (за исключением Сервисов) в соответствии с его функциональным назначением на условиях неисключительной, действующей на территории всего мира, не подлежащей переуступке лицензии, иных условиях и в объеме, определенных в Соглашении.</span>
                  <span>2.2. Право использования Сайта предоставляется Пользователю с даты его обращения к Сайту. Обращение Пользователя к Сайту означает принятие им условий Соглашения.</span>
                  <span>2.3. SpeedyTrade предоставляет Авторизованному Пользователю право использования Сервисов в соответствии с их функциональным назначением на условиях неисключительной, действующей на территории всего мира, не подлежащей переуступке лицензии, иных условиях и в объеме, определенных в Соглашении.</span>
                  <span>2.4. Право использования Сервисов предоставляется Авторизованному Пользователю с даты его регистрации на Сайте и явно выраженного им принятия (с использованием элементов интерфейса Сайта) Соглашения, Правил Сайта.</span>
                  <span>2.5. Право использования Сайта и его Сервисов предоставляется SpeedyTrade безвозмездно. При этом, по усмотрению SpeedyTrade условием предоставления доступа к определенным Сервисам может быть соблюдение Пользователем иных дополнительных требований, предусмотренных Соглашением.</span>
                  <span>2.6. Пользователь и Авторизованный Пользователь вправе обратиться к SpeedyTrade по любым вопросам, связанным с настоящим Соглашением:</span>
                  <span>по электронной почте: support@speedytrade.ru;</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей).</span>
                  <span>При обращении необходимо указать тему «Лицензионное соглашение».</span>
                  <span>3. РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ НА САЙТЕ</span>
                  <span>3.1. Для доступа к Сервисам Пользователю необходимо пройти процедуру регистрации (стать Авторизованным Пользователем) и авторизации с использованием Регистрационных данных.</span>
                  <span>3.2. Регистрация на Сайте осуществляется Пользователем в автоматическом (без участия SpeedyTrade) порядке с использованием технических средств Сайта. Регистрация осуществляется путем заполнения Пользователем регистрационных данных (регистрационная форма: https://speedytrade.ru/registration).</span>
                  <span>3.3. Пользователь обязуется предоставить в процессе регистрации достоверную и полную информацию о себе.</span>
                  <span>3.4. Регистрация на Сайте без согласия Пользователя с Соглашением и Правилами Сайта невозможна. Регистрацией Пользователь выражает полное и безоговорочное согласие с Соглашением и Правилами Сайта.</span>
                  <span>3.5. В результате регистрации Пользователь приобретает статус Авторизованного Пользователя и ему создается Личный кабинет на Сайте. Доступ к Личному кабинету, а также доступ к Сервисам Авторизованный Пользователь осуществляет путем авторизации по логину и паролю.</span>
                  <span>3.6. Авторизованный Пользователь обязуется сохранять конфиденциальность своего пароля. Все действия, совершаемые с использованием авторизации (логина и пароля Пользователя) на Сайте, считаются совершенными самим Авторизованным Пользователем.</span>
                  <span>3.7. При утере пароля Авторизованный Пользователь имеет возможность получить напоминание пароля на адрес электронной почты, указанный им при регистрации.</span>
                  <span>3.8. Регистрация на Сайте означает автоматическое согласие Авторизованного Пользователя получать на указанный им при регистрации адрес электронный почты сообщения информационного и рекламного характера от SpeedyTrade и его партнеров. Пользователь может в любое время отозвать это согласие, обратившись в SpeedyTrade:</span>
                  <span>по электронной почте: support@speedytrade.ru;</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей).</span>
                  <span>При обращении необходимо указать тему «Прочее».</span>
                  <span>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</span>
                  <span>4.1. SpeedyTrade обязуется:</span>
                  <span>рассматривать мотивированные претензии Пользователей и Авторизованных Пользователю по работе Сайта и его Сервисов;</span>
                  <span>консультировать Авторизованного Пользователя по вопросам функционирования Сервисов и о технических аспектах использования Сайта;</span>
                  <span>информировать Авторизованных Пользователей обо всех комиссиях, взимаемых при проведении расчетов на Сайте;</span>
                  <span>проводить проверку обращений Авторизованных Пользователей о потенциальных фактах мошенничества и/или нарушения положений Соглашения и/или Правил Сайта.</span>
                  <span>соблюдать иные свои обязанности, предусмотренные Соглашением.</span>
                  <span>4.2. SpeedyTrade вправе:</span>
                  <span>в любое время изменять оформление (дизайн) Сайта, его программное обеспечение, контент, перечень и состав Сервисов, осуществлять иные действия с целью улучшения качества и удобства использования Сайта и его Сервисов с предварительным уведомлением Пользователей и/или Авторизованных Пользователей или без такового;</span>
                  <span>в любое время и в любой форме запрашивать у Пользователя и Авторизованного Пользователя согласие на обработку его персональных данных на Сайте;</span>
                  <span>изменять условия регистрации или прекращать ее действие (временно или окончательно) с предварительным уведомлением или без такового;</span>
                  <span>изменять Правила Сайта с предварительным уведомлением или без такового;</span>
                  <span>в любое время и в любой форме запрашивать у Авторизованного Пользователя дополнительные подтверждения принятия им условий настоящего Соглашения и Правил Сайта;</span>
                  <span>предлагать Авторизованному Пользователю предоставление дополнительных услуг на Сайте. Предоставление таких дополнительных услуг может регулироваться отдельными документами (условиями, правилами, офертами Агента и т.д.). Документы, регулирующие отдельные вопросы, связанные с предоставлением SpeedyTrade дополнительных услуг на Сайте, действуют в части, не противоречащей Соглашению;</span>
                  <span>в любое время и по своему усмотрению вводить и отменять программы лояльности для Авторизованных Пользователей («титулы»; бонусы, купоны и пр.);</span>
                  <span>использовать данные переписки Авторизованных Пользователей в чате Сайта для контроля исполнения Сделки, по которой SpeedyTrade является Агентом, а также в рамках расследования фактов потенциального мошенничества и/или нарушения положений Соглашения и Правил Сайта по обращению других Авторизованных Пользователей;</span>
                  <span>при обнаружении прямых или косвенных фактов мошенничества или нарушения положений Соглашения «заморозить», заблокировать или удалить аккаунт Авторизованного Пользователя в одностороннем порядке, независимо от его баланса;</span>
                  <span>при проведении расследований правоохранительными органами предоставлять им доступные Агенту персональные данные Пользователей и Авторизованных пользователей, текущую локацию, историю браузера, fingerprint браузера, историю взаимодействия с Сайтом и/или его Сервисами и другим данным, благодаря которым можно получить полную или частичную информацию о местоположении и действиях злоумышленника;</span>
                  <span>проводить плановые технические работы на Сайте (устранение неисправностей, исправление ошибок, повышение безопасности работы, обновление и т.п.), в результате которых определенные Сервисы Сайта или Сайт полностью могут быть временно недоступны;</span>
                  <span>принять решение о закрытии Сайта с предварительным уведомлением об этом Авторизованного пользователя по адресу электронной почты, указанному им при регистрации, или иным способом, не менее, чем за один месяц до предполагаемой даты закрытия Сайта;</span>
                  <span>осуществлять иные действия, предусмотренные Соглашением.</span>
                  <span>4.3. Любой Пользователь, акцептовавший данное Соглашение вправе:</span>
                  <span>зарегистрироваться на Сайте в порядке, определенном Соглашением, для доступа к его Сервисам;</span>
                  <span>в любое время обратиться к SpeedyTrade по любому вопросу функционирования Сайта и Сервисов, а также для разъяснения положений настоящего Соглашения и/или Политики конфиденциальности Сайта:</span>
                  <span>по электронной почте: support@speedytrade.ru</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей);</span>
                  <span>в любое время отказаться от акцепта настоящего Соглашения и/или Правил Сайта, прекратив использование Сайта.</span>
                  <span>4.4. Авторизованный Пользователь дополнительно к правам, указанным в п.4.3. Соглашения, акцептовавший данное Соглашение, вправе:</span>
                  <span>размещать на Сайте пользовательский контент, в т.ч. Объявления о продаже Товара на Сайте, с учетом правил и гарантий, предусмотренных Соглашением;</span>
                  <span>принимать на свое усмотрение и под свою ответственность решение о заключении Сделки на Сайте;</span>
                  <span>в любой момент и по своему усмотрению вывести доступные средства Кошелька на свой банковский счет;</span>
                  <span>принимать (или не принимать) предложение SpeedyTrade о предоставлении дополнительных услуг на Сайте на основании отдельных соглашений (условий, правил, оферт Агента и т.д.);</span>
                  <span>осуществлять иные действия, предусмотренные Соглашением, с учетом установленных Соглашением правил и ограничений.</span>
                  <span>4.5. Любой Пользователь, акцептовавший данное Соглашение, обязан:</span>
                  <span>неукоснительно соблюдать положения настоящего Соглашения и Правила Сайта, в т.ч. установленные данными документами правила и ограничения;</span>
                  <span>самостоятельно и не реже чем раз в две недели проверять наличие обновлений Соглашения и Правил Сайта;</span>
                  <span>не использовать и не предпринимать попыток использовать Сайт способами, которые могут помешать нормальному функционированию Сайта и его Сервисов;</span>
                  <span>не осуществлять действия, направленные на дестабилизацию функционирования Сайта, не осуществлять попытки несанкционированного доступа к управлению Сайтом или его Сервисам, а также не осуществлять любые иные аналогичные действия;</span>
                  <span>воздерживаться от использования возможных технических уязвимостей Сайта (багов, "дыр" и т.п.), а при их обнаружении уведомить об этом SpeedyTrade в кратчайшие сроки:</span>
                  <span>по электронной почте: support@speedytrade.ru;</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей);</span>
                  <span>не использовать автоматизированные скрипты (программы) для сбора информации и/или взаимодействия с Сайтом;</span>
                  <span>не предпринимать попыток получения несанкционированного доступа к Личным кабинетам Авторизованных Пользователей;</span>
                  <span>прекратить использование Сайта в случае принятия решения об отзыве своего акцепта настоящего Соглашения.</span>
                  <span>4.6. Авторизованный Пользователь дополнительно к обязанностям, указанным в п.4.5. Соглашения, акцептовавший данное Соглашение, обязан:</span>
                  <span>строго следовать правилам размещения Объявлений, установленным Соглашением;</span>
                  <span>неукоснительно соблюдать правила и запреты, установленные Правилами Сайта;</span>
                  <span>следовать правилам общения в чате, установленным Соглашением;</span>
                  <span>соблюдать осмотрительность при выборе Продавца/Покупателя по Сделке;</span>
                  <span>проявлять добросовестность и не предпринимать попыток обмана или введения в заблуждение SpeedyTrade или других Пользователей;</span>
                  <span>самостоятельно удостовериться, что предложение, содержащееся в Объявлении на Сайте, является действительным и законным;</span>
                  <span>проводить Сделки только через Сайт, в соответствии с Соглашением и в рамках того технического функционала и тех технических ограничений, которые есть у Сайта. Нарушение любого из перечисленных условий является нарушением Соглашения;</span>
                  <span>не использовать дополнительные услуги, предлагаемые SpeedyTrade на Сайте в противоправных целях или в целях, которые могут каким-либо образом нанести ущерб SpeedyTrade и/или третьим лицам;</span>
                  <span>не разглашать конфиденциальную информацию, которая стала ему известна в результате взаимоотношений с SpeedyTrade;</span>
                  <span>сообщать о фактах мошенничества и/или нарушении Соглашения и/или Правил Сайта другими Пользователями и/или Авторизованными Пользователями, о которых ему стало известно, или подозрении на них в Службу поддержки Сайта по адресу: https://speedytrade.ru/account/callback;</span>
                  <span>оказывать содействие SpeedyTrade при рассмотрении споров, в которых Авторизованный пользователь является стороной;</span>
                  <span>в случае предъявления к SpeedyTrade претензий третьими лицами относительно размещенных Пользователем Объявлений/и или Товара, указанного в соответствующем Объявлении, самостоятельно и за свой счет урегулировать такие претензии, а в случае возникновения в связи с вышеуказанными претензиями у SpeedyTrade убытков, возместить их в полном объеме;</span>
                  <span>соблюдать иные свои обязанности, предусмотренные Соглашением и Правилами Сайта.</span>
                  <span>5. ПРАВИЛА РАЗМЕЩЕНИЯ ОБЪЯВЛЕНИЙ</span>
                  <span>5.1. Запрещено размещать на Сайте Объявления, содержание которых:</span>
                  <span>нарушает законодательство Российской Федерации (содержат пропаганду экстремизма, насилия, расовой ненависти, порнографию и пр.);</span>
                  <span>нарушает общепринятые нормы морали и нравственности;</span>
                  <span>является оскорбительным по отношению к другим Пользователям и третьим лицам, нарушающим их честь и достоинство;</span>
                  <span>является предложением Товара, для реализации которого требуется получение специальных разрешений, при отсутствии таких разрешений;</span>
                  <span>является предложением Товара, являющегося объектом исключительных прав SpeedyTrade или третьих лиц, при отсутствии необходимых разрешений от правообладателя;</span>
                  <span>является предложением Товара, оборот которого ограничен или запрещен действующим российским законодательством и/или международными соглашениями;</span>
                  <span>является предложением Товара, добытого неправомерным путем, в т.ч. путём кардинга, хакинга, брута, фишинга и т.п.</span>
                  <span>является заведомо ложным или способным ввести в заблуждение (например, указание ложных характеристик, неполной цены и т.д.);</span>
                  <span>составляет информация или инструкции по совершению незаконных действий, описание или пропаганду преступной деятельности, а также инструкции или руководства по совершению преступных действий;</span>
                  <span>включает файлы, содержащие вирусы или иные вредоносные программы.</span>
                  <span>5.2. Заголовок размещаемого Объявления должен соответствовать его содержанию (тексту) и не должен содержать контактную или персональную информацию о Пользователе (телефон, электронный адрес, адрес интернет-ресурса и пр.) или третьих лицах, а также спецсимволов или нечитаемых символов, не имеющих смысловой нагрузки.</span>
                  <span>5.3. Объявление на Сайте размещается в соответствии с тематикой разделов Сервиса. Запрещено размещение Объявления в разделе Сервиса, не соответствующем содержанию Объявления. Если Авторизованный пользователь не может найти или определить нужный раздел Сервиса, он вправе обратиться в Службу техподдержки Сайта за помощью.</span>
                  <span>5.4. Размещая Объявление на Сайте, Авторизованный Пользователь понимает, что материалы Объявления публикуются им в открытом доступе, то есть доступны для ознакомления любому посетителю Сайта (неограниченному кругу лиц). Авторизованный Пользователь понимает и принимает на себя все риски, связанные с таким размещением материалов, в т.ч. с включением в них своих персональных данных, в нарушение правил, установленных Соглашением. К таким рискам можно отнести, включая, но не ограничиваясь этим: риск попадания адреса электронной почты в списки для рассылки спам-сообщений, риск попадания адреса электронной почты к различного рода мошенникам, риск попадания телефонного номера к SMS-спамерам и/или SMS-мошенникам и иные риски, вытекающие из такого размещения материалов.</span>
                  <span>5.5. SpeedyTrade предоставляет Авторизованному Пользователю техническую возможность для создания Объявлений, но при этом не инициирует размещение материала в интересах или от имени Авторизованного Пользователя. Размещение материала Авторизованного Пользователя осуществляется исключительно по его инициативе и в результате его действий с использованием автоматизированных технических средств Сайта.</span>
                  <span>5.6. SpeedyTrade не контролирует конкретное размещение Объявления, не выбирает, не утверждает и не ограничивает конечных получателей информационных материалов, содержащихся в Объявлении.</span>
                  <span>5.7. SpeedyTrade вправе устанавливать и изменять требования к содержанию и условиям размещения Объявлений. При этом SpeedyTrade не контролирует (не отслеживает, не согласовывает и не утверждает) создание Объявления Авторизованным Пользователем, а также его содержание. Авторизованный Пользователь самостоятельно определяет содержание Объявления в рамках технического функционала, предоставляемого Сайтом, и правил, определенных в Соглашении.</span>
                  <span>5.8. SpeedyTrade ни при каких условиях и ни в каком случае не изменяет содержание размещаемого Авторизованным Пользователем Объявления, за исключением автоматизированных средств удаления спецсимволов для улучшения отображения Объявления. Авторизованный Пользователь понимает, что дизайн Сайта, отображение введенной им информации может меняться, но не сами данные Объявления Авторизованного Пользователя.</span>
                  <span>5.9. При размещении Объявления Авторизованный Пользователь, используя элементы интерфейса Сайта, явно подтверждает SpeedyTrade наличие у него права разместить Объявление в отношении соответствующего Товара или наличие соответствующего разрешения правообладателя Товара, если это применимо.</span>
                  <span>Размещение Объявления без предоставления Авторизованным Пользователем гарантий, предусмотренных настоящим пунктом Соглашения, невозможно.</span>
                  <span>Авторизованный Пользователь самостоятельно несет ответственность за нарушение им указанных в настоящем пункте гарантий.</span>
                  <span>5.10. Авторизованный Пользователь несет полную ответственность за размещаемые им в Объявлении информационные материалы как перед лицом, чьи права могут быть затронуты, так и перед SpeedyTrade за нарушение им условий Соглашения.</span>
                  <span>5.11. По обращению правообладателя или третьего лица, считающего, что размещение Авторизованным Пользователем Объявления на Сайте нарушают или способствует нарушению его прав, SpeedyTrade вправе обратиться к Авторизованному Пользователю с требованием об устранении последним оснований, повлекших соответствующее обращение. Авторизованный Пользователь сам производит оценку оснований обращения и принимает решение об их устранении.</span>
                  <span>5.12. SpeedyTrade вправе в одностороннем порядке удалить, скрыть или блокировать (делать недоступным третьим лицам) любой пользовательский контент, приостановить или ограничить доступ Авторизованного Пользователя к его Личному кабинету, а также отказаться в одностороннем порядке от Соглашения и удалить аккаунт Авторизованного Пользователя, если SpeedyTrade станет известно, что Авторизованный Пользователь при использовании Сервисов Сайта нарушает или способствует нарушению прав SpeedyTrade, прав третьих лиц (в том числе правообладателей и/или иных Пользователей Сайта), законодательства, условий Соглашения.</span>
                  <span>SpeedyTrade вправе совершить действия, предусмотренные настоящим пунктом, без предварительного уведомления Авторизованного Пользователя, если сочтет необходимым применить незамедлительное реагирование на нарушение или предполагаемое нарушение в соответствии с действующим законодательством.</span>
                  <span>Авторизованный Пользователь обязуется принять указанные действия SpeedyTrade, совершенные на основании настоящего пункта, вне зависимости от их оценки и оценки вызвавших их оснований самим Авторизованным Пользователем.</span>
                  <span>6. ПРАВИЛА ОБЩЕНИЯ В ЧАТЕ</span>
                  <span>6.1. При размещении сообщений в чате Авторизованному Пользователю не рекомендуется:</span>
                  <span>использовать при написании сообщения любые языки кроме русского;</span>
                  <span>создавать сообщения, относящиеся к оффтопу, флейму, флуду;</span>
                  <span>создавать неинформативные или бессодержательные сообщения;</span>
                  <span>злоупотреблять функциональной клавишей CAPS LOCK.</span>
                  <span>6.2. При размещении сообщений в чате Авторизованному Пользователю запрещено:</span>
                  <span>использовать высказывания вульгарного, непристойного характера, обсценную лексику;</span>
                  <span>оскорблять и дискриминировать других Авторизованных Пользователей или третьих лиц по любому признаку (национальному, половому, религиозному, расовому, возрастному, профессиональному или по любым другим признакам);</span>
                  <span>размещать персональные данные третьих лиц;</span>
                  <span>создавать сообщения, носящие характер призыва или пропаганды любого рода, противозаконных или противоправных действий;</span>
                  <span>использовать высказывания, содержащие проявления национальной, расовой, религиозной или любой другой нетерпимости, пропаганду экстремизма, фашизма и т.п.;</span>
                  <span>использовать ссылки на сторонние интернет-ресурсы и/или файлы, в т.ч. содержащие в себе информацию рекламного характера;</span>
                  <span>провоцировать других Авторизованных Пользователей на нарушение положений настоящего Соглашения или Правил Сайта;</span>
                  <span>призывать других Авторизованных Пользователей нарушать или способствовать нарушению прав SpeedyTrade и/или третьих лиц, положений настоящего Соглашения или Правил Сайта;</span>
                  <span>размещать какие-либо материалы с нарушением исключительных прав их правообладателей;</span>
                  <span>размещать файлы, содержащие вирусы или иные вредоносные программы;</span>
                  <span>размещать файлы, содержание которых нарушает правила для сообщений в чате, установленных настоящим пунктом Соглашения.</span>
                  <span>7. СДЕЛКА И РАСЧЕТЫ НА САЙТЕ</span>
                  <span>7.1. Покупатель, принявший решение о заключении Сделки, подтверждает свое намерение, используя элементы интерфейса Сервиса Сайта.</span>
                  <span>7.2. Для проведения расчетов по Сделке в Сервисе на Сайте Покупатель заключает с SpeedyTrade Агентский договор.</span>
                  <span>7.3. Сумма к оплате по Сделке автоматически рассчитывается Сервисом, исходя из:</span>
                  <span>текущего баланса Кошелька Покупателя;</span>
                  <span>цены и количества Товара, являющегося предметом Сделки;</span>
                  <span>суммы вознаграждения Агента;</span>
                  <span>комиссии платежной системы (удерживается платежной системой при перечислении средств).</span>
                  <span>7.4. Если Покупатель согласен с суммой к оплате по Сделке, он производит оплату способами, предусмотренными на Сайте.</span>
                  <span>7.5. После проведения Покупателем оплаты по Сделке, оплаченная им сумма за минусом безусловной части вознаграждения Агента и комиссии платежной системы, резервируется SpeedyTrade, а Продавец получает уведомление о произведенной Покупателем оплате.</span>
                  <span>7.6. Покупатель вправе отказаться от сделки.</span>
                  <span>В случае отказа Покупателем от сделки на основании настоящего пункта Соглашения, зарезервированная SpeedyTrade сумма (сумма, уплаченная им по Сделке за вычетом безусловной части вознаграждения Агента и удержанной платежной системой комиссии), переводится SpeedyTrade на его Кошелек.</span>
                  <span>7.7. Оформление передачи Товара осуществляется от Продавца к Покупателю при помощи Сервиса на Сайте. Используя интерфейс Сервиса Сайта, Продавец передает Покупателю всю необходимую для получения Товара информацию, а Покупатель после получения Товара подтверждает факт его получения.</span>
                  <span>7.8. После подтверждения покупателем факта получения Товара на Сайте Сделка считается завершенной. Зарезервированные SpeedyTrade средства по Сделке переводятся Продавцу за вычетом вознаграждения Агента по результатам исполнения поручения и отображаются в Кошельке Продавца.</span>
                  <span>8. РАССМОТРЕНИЕ СПОРОВ</span>
                  <span>8.1. Любой Авторизованный пользователь вправе обратиться к SpeedyTrade для разрешения Спора, возникшего на Сайте, в т.ч. связанного с потенциальным мошенничеством и/или подозрением на нарушение со стороны другого Авторизованного пользователя Соглашения и/или Правил Сайта.</span>
                  <span>8.2. SpeedyTrade проводит выяснение обстоятельств Спора, анализ доказательств сторон спора и выносит решение по существу спора. В ходе выяснения обстоятельств Спора SpeedyTrade вправе использовать как данные чата и иные сохраняемые Сайтом данные (в т.ч. логи), так и данные, предоставленные сторонами Спора.</span>
                  <span>8.3. Каждая из сторон Спора должна оказывать содействие в выяснении его обстоятельств и его разрешении.</span>
                  <span>8.4. Если одна из сторон Спора не оказывает содействия в выяснении его обстоятельств или препятствует такому выяснению, или уклоняется от него, SpeedyTrade вправе применить к такой стороне предусмотренные Соглашением и Правилами Сайта меры, в т.ч. блокировку аккаунта.</span>
                  <span>8.5. В случае, если у SpeedyTrade есть основания считать, что в ходе Сделки были нарушены положения Соглашения и/или Правила Сайта, SpeedyTrade вправе аннулировать соответствующую Сделку, вернуть средства по Сделке Покупателю, а также вправе реализовать иные свои права и меры в отношении виновных Авторизованных пользователей, предусмотренные Соглашением. Возможность возврата Покупателю средств по Сделке ограничивается суммой средств Кошелька Продавца.</span>
                  <span>8.6. Решение SpeedyTrade по существу Спора считается окончательным и не может быть оспорено. Авторизованный Пользователь обязуется принять указанное решение, вне зависимости от его согласия с ним и оценки обстоятельств Спора самим Авторизованным Пользователем.</span>
                  <span>9. ПРАВА ИНТЕЛЛЕКТАЛЬНОЙ СОБСТВЕННОСТИ</span>
                  <span>9.1. Исключительные права на программы для ЭВМ и базы данных, входящие в состав Сайта, а также дизайн Сайта и размещенные на Сайте товарные знаки SpeedyTrade, принадлежат SpeedyTrade.</span>
                  <span>9.2. Исключительные права на тексты статей, размещенные SpeedyTrade на Сайте, принадлежат их законным правообладателям и используются SpeedyTrade с их разрешения.</span>
                  <span>9.3. Исключительные права на изображения, наименования объектов авторского права и/или их персонажей, иные объекты исключительных прав третьих лиц, размещенные SpeedyTrade на Сайте, принадлежат их законным правообладателям и используются SpeedyTrade исключительно в информационных целях для ознакомления Пользователя об ассортименте и внешнем виде игр.</span>
                  <span>9.4. Пользователь и Авторизованный Пользователь гарантирует, что будет воздерживаться от любого использования Сайта за пределами прав, предоставленных по Соглашению, в т.ч. от нарушения исключительных прав третьих лиц, в связи с использованием Сайта, и в случае нарушения настоящей гарантии возместит SpeedyTrade и/или соответствующему третьему лицу причиненные в связи с этим нарушением и/или их устранением убытки в полном объеме.</span>
                  <span>9.5. Исключительное право на пользовательский контент, размещенный Авторизованным Пользователем на Сайте, принадлежит Авторизованному Пользователю или его законному правообладателю.</span>
                  <span>9.6. При размещении пользовательского контента на Сайте, Авторизованный Пользователь предоставляет SpeedyTrade право на его использование на условиях неисключительной, безвозмездной, действующей не территории всего мира и в течение всего срока действия исключительных прав лицензии, включая права на воспроизведение, распространение, публичный показ и доведение (сообщение) этого контента до всеобщего сведения.</span>
                  <span>9.7. Учитывая полное и безоговорочное принятие Авторизованным Пользователем настоящего Соглашения, Правил Сайта, а также дополнительные гарантии, предоставляемые Авторизованным Пользователем при размещении пользовательского контента на Сайте, SpeedyTrade исходит из того, что у Авторизованного Пользователя имеются все права (в т.ч. при необходимости соответствующие разрешения правообладателей) на размещение данного контента и предоставление прав на его использование в соответствии с п.9.6. Соглашения. При размещении пользовательского контента на Сайте Авторизованный Пользователь предоставляет SpeedyTrade право на его использование на условиях неисключительной, безвозмездной, действующей не территории всего мира и в течение всего срока действия исключительных прав, лицензии, включая права на воспроизведение, распространение, публичный показ и доведение (сообщение) этого контента до всеобщего сведения. </span>
                  <span>Авторизованный Пользователь в свою очередь гарантирует, что обладает необходимыми правами и разрешениями для предоставления на Сайте информации, указанной в п.9.7 Соглашения. Авторизованный Пользователь предоставляет SpeedyTrade право на использование предоставленной информации на условиях неисключительной, безвозмездной, действующей не территории всего мира и в течение всего срока действия исключительных прав, лицензии, включая права на воспроизведение, распространение, публичный показ и доведение (сообщение) этой информации до всеобщего сведения, и в случае нарушения настоящей гарантии обязуется возместить SpeedyTrade и/или соответствующему третьему лицу (правообладателю информации) причиненные в связи с этим нарушением и/или их устранением убытки в полном объеме.</span>
                  <span>9.8. Использование материалов Сайта допускается только с письменного разрешения их правообладателей за исключением случаев, прямо предусмотренных законом, или, когда на соответствующей странице Сайта имеется явное указание о возможности свободного использования ее материалов.</span>
                  <span>10. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</span>
                  <span>10.1. Пользование Сайтом осуществляется Пользователем и Авторизованным Пользователем самостоятельно, без непосредственного участия SpeedyTrade, на основе общепринятого в международной практике принципа "как есть" ("as is"). Это означает, что за проблемы, возникающие в процессе использования Сайта и его Сервисов (включая, но не ограничиваясь этим: проблемы совместимости с оборудованием Пользователя, типами и версиями используемых операционных систем и браузеров; несоответствие результатов использования Сайта и его Сервисов ожиданиям Пользователя и т.п.) SpeedyTrade ответственности не несет.</span>
                  <span>10.2. SpeedyTrade не дает каких-либо гарантий по совместимости Сайта с оборудованием Пользователя, по его функциональности, отсутствию дефектов программного обеспечения Сайта и его Сервисов и не несет какой-либо ответственности, в связи с этим.</span>
                  <span>10.3. SpeedyTrade ни при каких обстоятельствах не несет ответственности за ущерб, убытки или расходы, возникшие у Пользователя в связи с использованием или невозможностью использования Сайта и/или его Сервисов.</span>
                  <span>10.4. SpeedyTrade не обязано, но вправе по своему усмотрению, осуществлять техническую поддержку и обновление Сайта. Обновленный Сайт может содержать исправление дефектов программного обеспечения Сайта и его Сервисов, а также новые и/или измененные функции.</span>
                  <span>10.5. Авторизованный Пользователь осознает и согласен с тем, что он самостоятельно оценивает все риски, связанные с размещением, распространением Объявлений, а также принятием решения о Сделке, в т.ч. должен самостоятельно удостовериться, что предложение, содержащееся в Объявлении, является действительным и законным.</span>
                  <span>10.6. SpeedyTrade не выбирает, не утверждает и не ограничивает конечных получателей Объявлений, а также, в связи с этим, не гарантирует, что опубликованное Авторизованным Пользователем Объявление будет просмотрено определенным количеством лиц или хотя бы одним.</span>
                  <span>10.7. SpeedyTrade не является стороной или заинтересованным лицом по Сделке между Покупателем и Продавцом, а потому не несет ответственность за последствия этой Сделки. Ответственность SpeedyTrade как Агента, если между Покупателем и SpeedyTrade был заключен агентский договор, ограничивается ответственностью, предусмотренной по такому договору.</span>
                  <span>10.8. Гарантии SpeedyTrade при рассмотрении Споров между Пользователями на Сайте и возврату средств по Сделкам, совершенным с нарушением Соглашения и Правил Сайта, ограничиваются размером средств на Кошельке Авторизованного пользователя, совершившего соответствующее нарушение.</span>
                  <span>10.9. SpeedyTrade ни при каких условиях не несет ответственность за действия Пользователей и Авторизованных Пользователей, произведенные за пределами Сайта и его Сервисов.</span>
                  <span>11. ПОРЯДОК ЗАКЛЮЧЕНИЯ И ИЗМЕНЕНИЯ СОГЛАШЕНИЯ, СРОК ЕГО ДЕЙСТВИЯ</span>
                  <span>11.1. Обращение Пользователя к Сайту означает его полный и безоговорочный Акцепт всех условий Соглашения.</span>
                  <span>11.2. Пройдя процедуру регистрации на Сайте и приняв Соглашение путем нажатия соответствующей кнопки в конце текста Соглашения или иным способом, предусмотренным интерфейсом Сайта, Пользователь приобретает все права и обязанности Авторизованного Пользователя и считается полностью принявшим условия Соглашения без каких-либо оговорок и ограничений.</span>
                  <span>11.3. Принимая условия Соглашения, Пользователь или Авторизованный Пользователь подтверждает, что обладает дееспособностью, правами и полномочиями на заключение Соглашения, и полностью понимает и осознает значение своих действий.</span>
                  <span>11.4. Соглашение вступает в силу с момента его заключения (Акцепта) и действует до исполнения Сторонами своих обязательств по нему или наступления следующих событий:</span>
                  <span>принятия SpeedyTrade решения о закрытии Сайта;</span>
                  <span>принятия SpeedyTrade решения о расторжении Соглашения с Авторизованным Пользователем в одностороннем порядке на основаниях, предусмотренных Соглашением, в части положений Соглашения, касающихся использования Сервисов Сайта;</span>
                  <span>принятия решения Пользователем или Авторизованным Пользователем о расторжении Соглашения.</span>
                  <span>11.5. В случае прекращения действия Соглашения (полностью или в части), Пользователь и Авторизованный Пользователь обязан незамедлительно прекратить использование Сайта и/или его Сервисов в части тех положений Соглашения, которые прекратили свое действие.</span>
                  <span>11.6. SpeedyTrade вправе в любое время и по своему усмотрению вносить изменения в Соглашение. В случае внесения изменений в Соглашение его обновленная версия публикуется на Сайте в разделе https://speedytrade.ru/license-agreement.</span>
                  <span>Обновленная версия Соглашения вступает в силу с даты публикации. Актуальная дата публикации обновленной версии Соглашения всегда будет указана непосредственно под текстом документа.</span>
                  <span>SpeedyTrade рекомендует Пользователю время от времени просматривать текст Соглашения, чтобы отслеживать его возможные изменения. Продолжение использования Пользователем Сайта после публикации изменений в Соглашении означает его согласие с данными изменениями. Авторизованный Пользователь получает уведомление об изменении Соглашения по адресу электронной почты, указанной им при регистрации, или иным способом с учетом технических возможностей Сайта.</span>
                  <span>11.7. Если Пользователь или Авторизованный Пользователь не согласен с каким-либо условием Соглашения или оно ему непонятно, он не должен акцептовать Соглашение предусмотренным для него способом до устранения неясностей или несогласия. Пользователь и Авторизованный Пользователь может обратиться к SpeedyTrade по любым вопросам, связанным с Соглашением и его условиями:</span>
                  <span>по электронной почте: support@speedytrade.ru;</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей).</span>
                  <span>При необходимо указать тему «Лицензионное соглашение».</span>
                  <span>12. ИНЫЕ ПОЛОЖЕНИЯ</span>
                  <span>12.1. Независимо от места нахождения Пользователя или Авторизованного Пользователя, применимым правом по Соглашению является право Российской Федерации, в соответствии с которым разрешаются все споры и разногласия между Сторонами.</span>
                  <span>12.2. Стороны будут стремиться к разрешению споров и разногласий по Соглашению или в связи с ним путем переговоров.</span>
                  <span>12.3. Если по тем или иным причинам одно или несколько положений Соглашения будут признаны недействительными или не имеющими юридической силы, то данное обстоятельство не влияет на действительность или применимость остальных положений Соглашения.</span>
                  <span>12.4. Стороны признают юридическую силу за:</span>
                  <span>электронными письмами и документами, направленными по электронной почте (cо стороны SpeedyTrade это адрес электронной почты, указанный на Сайте, со стороны Авторизованного Пользователя – адрес электронной почты, указанный при регистрации);</span>
                  <span>согласиями и гарантиями, полученными от Пользователя и Авторизованного Пользователя с использованием элементов интерфейса Сайта.</span>
                </Row>
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main >
  );
};

export default LicenseAgreement;