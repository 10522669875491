import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bgImg from '../assets/imgs/bg/gradient.jpg';

const AgencyContract = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Агентский договор</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <Row className='gx-3 gx-md-4 gx-lg-5' >
                  <span>Настоящий агентский договор (далее — Договор), является публичной офертой ИП Разуваева Елена Сергеевна ИНН: 323203455433 ОГРН: 324320000033480 (далее – SpeedyTrade или Агент) на совершение расчетов от имени и за счет Покупателя (далее – Покупатель или Принципал) на сайте https://speedytrade.ru (далее – Сайт) на условиях, приведенных ниже.</span>
                  <span>Термины и определения, используемые в настоящем Договоре, раскрыты в заключенном Сторонами Лицензионном соглашении, размещенном по адресу: https://speedytrade.ru/license-agreement.</span>
                  <span>1. ПРЕДМЕТ ДОГОВОРА</span>
                  <span>1.1. По настоящему договору SpeedyTrade, действующий в качестве Агента, обязуется совершать от имени Покупателя, действующего в качестве Принципала, и за его счет расчеты по исполнению Сделок, заключаемых Покупателем с Продавцами через Сайт, а Принципал обязуется выплачивать Агенту агентское вознаграждение. </span>
                  <span>1.2. Оформление Покупателем Сделки с Продавцом через Сервис Сайта означает автоматическое поручение Покупателем Агенту в соответствии с Договором осуществить расчеты по оформленной Сделке.</span>
                  <span>1.3. Агент вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</span>
                  <span>2. ВОЗНАГРАЖДЕНИЕ И РАСХОДЫ АГЕНТА</span>
                  <span>2.1. Вознаграждение выплачивается Принципалом Агенту отдельно по каждому поручению и состоит из двух частей:</span>
                  <span>безусловная часть вознаграждения;</span>
                  <span>вознаграждение по результатам исполнения поручения.</span>
                  <span>2.2. Размер вознаграждения Агента, а также каждой его части, зависит от суммы, подлежащей перечислению Агентом Продавцу по поручению Принципала (цены поручения).</span>
                  <span>2.3. Размер безусловной части вознаграждения Агента по одному поручению не может превышать 5% от цены поручения (суммы, перечисленной Агентом Продавцу по поручению Принципала).</span>
                  <span>2.4. Размер вознаграждения Агента по результатам совершения расчета по одному поручению не может превышать 5% от цены поручения.</span>
                  <span>2.5. Общая сумма вознаграждения Агента по одному поручению не может превышать 10% от цены поручения.</span>
                  <span>2.6. Расходы, понесенные Агентом в порядке исполнения Агентский договора, компенсируются Принципалом по мере их возникновения.</span>
                  <span>3. ПОРЯДОК ВЗАИМОДЕЙСТВИЯ СТОРОН</span>
                  <span>3.1. Принципал (Покупатель), поручающий Агенту совершить расчет по Сделке, перечисляет Агенту денежные средства в сумме, достаточной для обеспечения исполнения соответствующего поручения, размер которых включает:</span>
                  <span>сумму, подлежащую перечислению Продавцу;</span>
                  <span>вознаграждение Агента.</span>
                  <span>3.2. При перечислении Покупателем Агенту денежных средств, Покупатель дополнительно оплачивает комиссию, которая удерживается платежной системой при осуществлении расчета Продавцу по Сделке.</span>
                  <span>3.3. Агент удерживает из денежной суммы, полученной от Покупателя, безусловную часть своего вознаграждения и помещает оставшуюся денежную сумму на депозит до получения дополнительных указаний Покупателя.</span>
                  <span>3.4. После получения указания Покупателя о перечислении денежных средств Продавцу Агент перечисляет на Кошелек Продавца размещенные на депозите денежные средства, за вычетом суммы своего вознаграждения по результатам исполнения поручения, и предоставляет Покупателю отчет об исполнении поручения.</span>
                  <span>3.5. Моментом исполнения Агентом поручения Покупателя является момент зачисления на Кошелек Продавца подлежащей перечислению ему денежной суммы.</span>
                  <span>3.6. Отчетом Агента по конкретному поручению Покупателя является размещенная в Личном кабинете Покупателя информация о произведенных с Продавцом расчетах. Доказательства расходов, произведенных Агентом за счет Покупателя, в отчете Агента не указываются.</span>
                  <span>3.7. Покупатель, имеющий возражения по отчету Агента, должен сообщить о них в течение 24 часов. В противном случае отчет Агента считается принятым Покупателем.</span>
                  <span>3.8. Покупатель вправе отказаться от поручения в любой момент до выдачи им указания Агенту о перечислении денежных средств Продавцу.</span>
                  <span>3.9. В случае отказа Покупателя от поручения, произошедшего после размещения Агентом денежных средств на депозите и до выдачи указания Агенту о перечислении этих средств Продавцу, вся сумма депозита (сумма, подлежащую перечислению Продавцу, и сумма вознаграждения по результатам исполнения поручения) переводится Агентом на Кошелек Покупателя. Безусловная часть вознаграждения Агента не возвращается.</span>
                  <span>3.10. В случае последующей отмены Сделки и возврата денежных средств Покупателю в случаях, предусмотренных Лицензионным соглашением, такой возврат производится на условиях, определяемых Лицензионным соглашением, и не включает в себя вознаграждение Агента, т.е. возврату подлежит только сумма, которая подлежала перечислению Продавцу по указанию Покупателя.</span>
                  <span>4. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</span>
                  <span>4.1. Агент не несет ответственности за нарушение сроков, указанных в разделе 3 Договора, в случае частичной или полной недоступности Сайта и его Сервисов по причине проведения профилактических или иных работ, или по любым другим причинам технического характера.</span>
                  <span>4.2. В случае проведения таких работ или наступления причин, повлекших полную или частичную недоступность Сайта и/или его Сервисов, сроки, указанные в разделе 3 Договора, продлеваются на срок выполнения соответствующих работ или устранения причин, вызвавших частичную или полную недоступность Сайта и/или его Сервисов.</span>
                  <span>4.3. Агент не несет никаких иных дополнительных гарантий в отношении работы Сайта и его Сервисов, а также в связи с использованием или невозможностью использования Сайта и/или его Сервисов, сверх гарантий, установленных Лицензионным соглашением.</span>
                  <span>4.4. Агент не является стороной или заинтересованным лицом по Сделке между Покупателем и Продавцом, а потому не несет ответственность за последствия этой Сделки или последствия признания ее недействительной. Ответственность Агента ограничивается ответственностью, предусмотренной Договором.</span>
                  <span>5. ПОРЯДОК ЗАКЛЮЧЕНИЯ И ИЗМЕНЕНИЯ ДОГОВОРА, СРОК ЕГО ДЕЙСТВИЯ</span>
                  <span>5.1. Принимая Договор путем нажатия соответствующей кнопки в конце текста Договора или иным способом, предусмотренным интерфейсом Сайта, Покупатель дает полное и безоговорочное согласие на условия, изложенные в Договоре.</span>
                  <span>5.2. Принимая условия Договора, Покупатель подтверждает, что обладает дееспособностью, правами и полномочиями на заключение Договора, полностью понимает и осознает значение своих действий.</span>
                  <span>5.3. Договор вступает в силу с момента его заключения (акцепта) и действует до исполнения Сторонами своих обязательств по нему или наступления следующих событий:</span>
                  <span>расторжения Сторонами Лицензионного соглашения;</span>
                  <span>принятия решения Покупателем о расторжении Договора.</span>
                  <span>5.4. Агент вправе в любое время и по своему усмотрению вносить изменения в Договор. В случае внесения изменений в Договор его обновленная версия публикуется на Сайте в разделе https://speedytrade.ru/agency.</span>
                  <span>Обновленная версия Договора вступает в силу с даты его публикации. Актуальная дата всегда будет указана непосредственно под текстом документа.</span>
                  <span>Покупатель получает уведомление об изменении Договора по адресу электронной почты, указанной им при регистрации. Продолжение использования Покупателем Сервисов Сайта после публикации изменений в Договоре означает его согласие с данными изменениями.</span>
                  <span>5.5. Если Покупатель не согласен с каким-либо условием Договора или оно ему непонятно, он не должен акцептовать Договор, предусмотренным на Сайте способом, до устранения неясностей или несогласия.</span>
                  <span>6. ИНЫЕ ПОЛОЖЕНИЯ</span>
                  <span>6.1. Независимо от места нахождения Покупателя к возникшим из Договора отношениям применяется законодательство Российской Федерации, в соответствии с которым разрешаются все споры и разногласия между Сторонами.</span>
                  <span>6.2. Стороны будут стремиться к разрешению споров и разногласий по Договору или в связи с ним, путем переговоров.</span>
                  <span>6.3. Признание по тем или иным причинам одного или нескольких положений Договора недействительными или не имеющими юридической силы не влияет на действительность или применимость остальных положений Договора.</span>
                  <span>6.4. В ходе исполнения прав и обязанностей по настоящему Договору, а также при разрешении возникших споров, Стороны признают имеющими юридическую силу и доказательственное значение: электронные письма и документы, направленные по электронной почте (со стороны Агента - адрес электронной почты, указанный на Сайте, со стороны Покупателя - адрес электронной почты, указанный им при регистрации на Сайте); согласия и гарантии, полученные от Покупателя с использованием элементов интерфейса Сайта.</span>

                </Row>
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default AgencyContract;