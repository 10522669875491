import React from 'react';
import Container from 'react-bootstrap/Container';
import bgImg from '../assets/imgs/bg/gradient.jpg';
import { Row } from 'react-bootstrap';

const Requisites = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Реквизиты</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <Row className='gx-3 gx-md-4 gx-lg-5' >
                  <span>ИП Разуваева Елена Сергеевна</span>
                  <span>Номер счета: 40802810132250006573</span>
                  <span>ИНН: 323203455433</span>
                  <span>ОГРН: 324320000033480</span>
                  <span>Юридический адрес: 241035, РОССИЯ, Брянская, Брянск, Улица Медведева, д. 9а, c. 1, квартира/офис 7</span>

                  <span>Почта: speedytrade@mail.ru</span>
                </Row>

              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Requisites;