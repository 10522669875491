import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Logo from "../assets/images/speedytradeLogo.svg";
//icons
import Plaix from './svg/Plaix';
import { RxCross1 } from "react-icons/rx";
import { IoGridOutline, IoHomeOutline, IoSearchOutline } from 'react-icons/io5';
import { VscAccount, VscChromeClose, VscCommentDiscussion } from 'react-icons/vsc';
import { FiSearch } from 'react-icons/fi';
import { SlSocialVkontakte } from "react-icons/sl";
import { BsTelegram } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import Sign from './utils/Sign';
import useDebounce from '../hooks/useDebounce';
import { getSearch } from '../services/search';
import GameCard from './GameCard';


const Footer = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const unreadCount = useSelector((state) => state.notification.message);

  const [showMenu, setShowMenu] = useState(false);
  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);

  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 900);

  const [games, setGames] = useState({ items: [], loading: true });;

  useEffect(() => {
    if (debouncedSearchTerm) {
      getSearch(debouncedSearchTerm)
        .then((res) => {
          setGames(prev => ({
            ...prev,
            items: res,
            loading: false
          }));
        })
        .catch(() => setGames((prev) => ({ ...prev, loading: false })));
    }
  }, [debouncedSearchTerm]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
      <footer>
        <Container className="d-none d-md-flex">
          <img src={Logo} alt="speedytrade" className="logo" />
          <div className="d-flex flex-column-reverse flex-xl-row align-items-center">
            <Sign className='text-center fs-09 mt-1 mt-xl-0' />
            <img src="/imgs/cards.png" alt="cards" className='cards' />
            <ul className='list-unstyled mx-4 mt-2 mt-xl-0'>
              <li><Link to='/privacy' target="_blank">Политика конфиденциальности</Link></li>
              <li><Link to='/rules' target="_blank">Правила Сайта</Link></li>
              <li><Link to='/agency' target="_blank">Агентский договор</Link></li>
              <li><Link to='/public-offer' target="_blank"> Публичная оферта безопасной сделки</Link></li>
              <li><Link to='/license-agreement' target="_blank">Лицензионное соглашение</Link></li>
              <li><Link to='/requisites' target="_blank">Реквизиты</Link></li>
            </ul>
            <ul className='list-unstyled'>
              <li>
                <a href="/" className='social-link'>
                  <SlSocialVkontakte color={'blue'} />
                </a>
              </li>
              <li className='ms-2'>
                <a href="/" className='social-link'>
                  <BsTelegram color={'#32A8E2'} />
                </a>
              </li>
              <li className='ms-2'>
                <a href="/" className='social-link'>
                  <AiFillYoutube color={'red'} />
                </a>
              </li>
              <li className='ms-2'>
                <a href="/" className='social-link'>
                  <AiFillYoutube color={'red'} />
                </a>
              </li>
            </ul>
          </div>
          <a href="/" className='dev'>
            Создано в <span className='color'>PlaiX</span>
          </a>

        </Container>

        <Container className="d-md-none">
          <nav className="mobile w-100">
            <ul className="list-unstyled d-flex align-items-center justify-content-around">
              <li>
                <Link to="/" onClick={() => { handleCloseMenu(), setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) }}>
                  <IoHomeOutline />
                </Link>
              </li>
              <li>
                <button
                  type="button"
                  to="/"
                  onClick={
                    showSearch
                      ? (() => { setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) })
                      : (() => { setShowSearch(true), handleCloseMenu() })
                  }
                >
                  {showSearch ? <VscChromeClose /> : <IoSearchOutline />}
                </button>
              </li>
              <li>
                <Link to={isAuth ? '/account' : '/login'} onClick={() => { handleCloseMenu(), setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) }}>
                  <VscAccount />
                </Link>
              </li>
              <li>
                <Link to={isAuth ? '/account/messages' : '/login'} onClick={() => { handleCloseMenu(), setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) }}>
                  <VscCommentDiscussion />
                  {unreadCount && unreadCount > 0 ? <span className="unread-header-conversations-count">{parseInt(unreadCount)}</span> : ""}
                </Link>
              </li>
              <li>
                <button type="button" to="/" onClick={showMenu ? (() => { handleCloseMenu() }) : (() => { handleShowMenu(), setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) })}>
                  {showMenu ? <VscChromeClose /> : <IoGridOutline />}
                </button>
              </li>
            </ul>
          </nav>
        </Container>
      </footer >

      <Offcanvas
        show={showSearch}
        scroll={true}
        onEscapeKeyDown={() => setShowSearch(false)}
        placement={'bottom'}
        autoFocus={false}
        onHide={() => { setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) }}
      >
        <Offcanvas.Body>
          <Container>

            <div className="form-search w-100 z-1052">
              <input
                type="search"
                value={searchTerm}
                placeholder="Поиск по играм"
                onChange={(event) => {
                  handleSearchChange(event);
                }}
              />
              <button type="submit">
                <FiSearch />
              </button>
            </div>
            {debouncedSearchTerm &&
              <section className='sec-favorites'>

                {games.items.length > 0 ?
                  <ul className="list-unstyled gy-3 gx-3 gx-xl-5 row row-cols-lg-4 row-cols-md-3 row-cols-2">
                    <GameCard term={searchTerm} onSearch={() => { setShowSearch(false), setSearchTerm(""), setGames({ items: [], loading: true }) }} param2={games.items} />
                  </ul>
                  :
                  <h5 className="text-center">Мы не смогли найти такую игру</h5>
                }
              </section>
            }
          </Container>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showMenu}
        scroll={true}
        placement={'end'}
        onHide={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        <Offcanvas.Body>
          <Container className="h-100 d-flex flex-column justify-content-between pt-5">
            <nav className="offcanvas-menu">
              <ul className="list-unstyled fs-12">
                <li className="mb-3">
                  <Link to="/blog" target="_blank">
                    Новости
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/privacy" target="_blank">
                    Политика конфиденциальности
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/rules" target="_blank">
                    Правила Сайта
                  </Link>
                </li>
                <li className="mb-3">
                  <Link to="/agency" target="_blank">
                    Агентский договор
                  </Link>
                </li>
                <li className="mb-3" >
                  <Link to='/public-offer' target="_blank"> Публичная оферта безопасной сделки</Link>
                </li>
                <li className="mb-3" >
                  <Link to="/license-agreement" target="_blank">
                    Лицензионное соглашение
                  </Link>
                </li>
                <li>
                  <ul className='list-unstyled d-flex align-items-center'>
                    <li>
                      <a href="/" className='social-link'>
                        <SlSocialVkontakte color={'blue'} />
                      </a>
                    </li>
                    <li className='ms-2'>
                      <a href="/" className='social-link'>
                        <BsTelegram color={'#32A8E2'} />
                      </a>
                    </li>
                    <li className='ms-2'>
                      <a href="/" className='social-link'>
                        <AiFillYoutube color={'red'} />
                      </a>
                    </li>
                  </ul>
                  <img src="/imgs/cards.png" alt="cards" className='cards' />
                </li>
              </ul>
            </nav>
            <div className="d-flex flex-column align-items-end mt-5">
              <Sign />
              <a href="/" className='dev'>
                Создано в <span className='color'>PlaiX</span>
              </a>
            </div>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Footer;